/*eslint-disable */
import mock from "../mock";
import axios from "axios";
import { get } from "sortablejs";
import useJwt from "@src/auth/jwt/useJwt";

const config = useJwt.jwtConfig;

const date = new Date();
const prevDay = new Date().getDate() - 1;
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

// prettier-ignore
const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// prettier-ignore
const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

let events = [];
// const data = {
//   events: [
//     {
//       id: 1,
//       url: "",
//       title: "Design Review",
//       start: date,
//       end: nextDay,
//       allDay: false,
//       extendedProps: {
//         calendar: "Business",
//       },
//     },
//     {
//       id: 2,
//       url: "",
//       title: "Meeting With Client",
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
//       allDay: true,
//       extendedProps: {
//         calendar: "Business",
//       },
//     },
//     {
//       id: 3,
//       url: "",
//       title: "Family Trip",
//       allDay: true,
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -9),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -7),
//       extendedProps: {
//         calendar: "Holiday",
//       },
//     },
//     {
//       id: 4,
//       url: "",
//       title: "Doctor's Appointment",
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
//       allDay: true,
//       extendedProps: {
//         calendar: "Personal",
//       },
//     },
//     {
//       id: 5,
//       url: "",
//       title: "Dart Game?",
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
//       allDay: true,
//       extendedProps: {
//         calendar: "ETC",
//       },
//     },
//     {
//       id: 6,
//       url: "",
//       title: "Meditation",
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
//       allDay: true,
//       extendedProps: {
//         calendar: "Personal",
//       },
//     },
//     {
//       id: 7,
//       url: "",
//       title: "Dinner",
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
//       allDay: true,
//       extendedProps: {
//         calendar: "Family",
//       },
//     },
//     {
//       id: 8,
//       url: "",
//       title: "Product Review",
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
//       allDay: true,
//       extendedProps: {
//         calendar: "Business",
//       },
//     },
//     {
//       id: 9,
//       url: "",
//       title: "Monthly Meeting",
//       start: nextMonth,
//       end: nextMonth,
//       allDay: true,
//       extendedProps: {
//         calendar: "Business",
//       },
//     },
//     {
//       id: 10,
//       url: "",
//       title: "Monthly Checkup",
//       start: prevMonth,
//       end: prevMonth,
//       allDay: true,
//       extendedProps: {
//         calendar: "Personal",
//       },
//     },
//   ],
// };

// ------------------------------------------------
// GET: Return calendar events
// ------------------------------------------------
const API_URL = process.env.REACT_APP_BACKEND_URL;

mock.onGet("/apps/calendar/events").reply(async () => {
  // Get requested calendars as Array
  // const calendars = config.calendars
  // return [200, data.events.filter(event => calendars.includes(event.extendedProps.calendar))]
  try {
    const response = await axios.get(API_URL + "events", {
      headers: {
        "x-access-token": `Bearer ${localStorage.getItem(
          config.storageTokenKeyName
        )}`,
        authorization: `Bearer ${localStorage.getItem(
          config.storageTokenKeyName
        )}`,
        Authorization: `Bearer ${localStorage.getItem(
          config.storageTokenKeyName
        )}`,
      },
    });
    let { data } = await response.data;
    console.log(data);
    let data1 = [];
    data.map((d) => {
      let date = new Date(d.organization_date) + 24 * 60 * 60 * 1000;

      const abc = {
        id: d.id,
        url: "",
        title: d.name,
        start: d.organization_date,
        end: date,
        visibilty: d.is_visible,
        allDay: true,
        extendedProps: {},
      };
      data1.push(abc);
    });
    // data = [
    //   {
    //     id: 1,
    //     url: "",
    //     title: "Design Review",
    //     start: date,
    //     end: nextDay,
    //     allDay: true,
    //     extendedProps: {
    //       calendar: "",
    //     },
    //   },
    // ];

    return [200, data1];
  } catch (error) {
    console.log("error :", error);
  }
  // return [200, data.events];
});

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost("/apps/calendar/add-event").reply(async (configs) => {
  // Get event from post data
  const { event } = JSON.parse(configs.data);
  // const { length } = data.events.length;

  // let lastIndex = 0;
  // if (length) {
  //   lastIndex = data.events[length - 1].id;
  // }
  // event.id = lastIndex + 1;
  console.log(event);
  try {
    const response = await fetch(API_URL + "events", {
      method: "POST",
      headers: {
        "x-access-token": `Bearer ${localStorage.getItem(
          config.storageTokenKeyName
        )}`,
        authorization: `Bearer ${localStorage.getItem(
          config.storageTokenKeyName
        )}`,
        Authorization: `Bearer ${localStorage.getItem(
          config.storageTokenKeyName
        )}`,
        "content-type": "application/json",
      },
      body: JSON.stringify({
        name: event.title,
        image: "hello.jpg",
        is_visible: event.visibilty,
        location: event.extendedProps.location,
        organization_date: event.start,
        contributed_price: event.extendedProps.pricePaid,
        guest_price: event.extendedProps.priceGuests,
        noncontributed_price: event.extendedProps.priceNonPaid,
        guest_members: event.extendedProps.guests,
        noncontributed_members: event.extendedProps.nonPaid,
        contributed_members: event.extendedProps.paid,
      }),
    });
  } catch (error) {
    console.log("error :", error);
  }
  // data.events.push(event);
  return [201, { event }];
});

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost("/apps/calendar/update-event").reply(async (configs) => {
  const { event: eventData } = JSON.parse(configs.data);
  // const { event: eventData } = JSON.parse(configs.data);
  // // Convert Id to number
  eventData.id = Number(eventData.id);
  // const event = data.events.find((ev) => ev.id === Number(eventData.id));
  // Object.assign(event, eventData);
  console.log(eventData);

  try {
    const response = await fetch(`${API_URL}events/${eventData.id}`, {
      method: "PUT",
      headers: {
        "x-access-token": `Bearer ${localStorage.getItem(
          config.storageTokenKeyName
        )}`,
        authorization: `Bearer ${localStorage.getItem(
          config.storageTokenKeyName
        )}`,
        Authorization: `Bearer ${localStorage.getItem(
          config.storageTokenKeyName
        )}`,
        "content-type": "application/json",
      },
      body: JSON.stringify({
        name: eventData.title,
        image: "hello.jpg",
        is_visible: eventData.visibilty,
        organization_date: eventData.start,
        contributed_price: eventData.extendedProps.pricePaid,
        guest_price: eventData.extendedProps.priceGuests,
        noncontributed_price: eventData.extendedProps.priceNonPaid,
        guest_members: eventData.extendedProps.guests,
        noncontributed_members: eventData.extendedProps.nonPaid,
        contributed_members: eventData.extendedProps.paid,
      }),
    });
  } catch (error) {
    console.log("error :", error);
  }

  return [200, { event }];
});

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete("/apps/calendar/remove-event").reply((configs) => {
  // Get event id from URL
  let { id } = configs;

  // Convert Id to number
  const eventId = Number(id);

  const eventIndex = data.events.findIndex((ev) => ev.id === eventId);
  data.events.splice(eventIndex, 1);
  return [200];
});
